<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-layout pt-2 wrap justify-start>
      <v-flex xs12 text-left>
        <span
          style="font-family: opensansbold; font-size: 14px; color: #808080"
        >
          Graph - Nationality
        </span>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-end>
      <v-flex align-self-end xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          color="#68D389"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From Date"
              outlined
              color="#68D389"
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            color="#68D389"
            v-model="fromDate"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          color="#68D389"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              label="To Date"
              outlined
              color="#68D389"
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            color="#68D389"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="flag">
      <v-flex xs12 pa-4>
        <v-layout wrap>
          <v-flex xs12 sm4 pa-2 v-for="(items,g) in graphData" :key="g">
            <span class="poppinssemibold">
              {{ items.label  }} - <span style="color:red">{{ items.count }}</span>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="series.length > 0">
        <apexchart
          type="pie"
          width="100%"
          height="400px"
          :options="chartOptions"
          class="graphLabel"
          :series="series"
        ></apexchart>
        <!-- <apexchart
          width="100%"
          height="300px"
          type="pie"
          :options="chartOptions"
          :series="series"
        ></apexchart> -->
      </v-flex>
    </v-layout>

    <!-- <v-layout wrap justify-center v-if="flag">
      <v-flex v-if="series.length > 0"> No payments </v-flex>
    </v-layout> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      ServerError: false,
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      graphData: [],
      flag: false,
      series: [56, 34, 10],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Indian", "Foreigner", "Children"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        // colors: ["#c52b30", "#373485"],
      },
    };
  },
  watch: {
    fromDate() {
      this.checkDate();
      this.getData();
    },
    toDate() {
      // if (this.fromDate)
      this.getData();
    },
  },
  beforeMount() {
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate());
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    // this.getData();
  },
  methods: {
    checkDate() {
      let ndate = new Date().toISOString().substr(0, 10);
      if (this.fromDate == ndate) {
        // this.fromDate = null;
        const tomorrow = new Date(this.fromDate);
        tomorrow.setDate(tomorrow.getDate() - (6*30));
        this.fromDate = tomorrow.toISOString().substr(0, 10);
      }
      let tdate = new Date().toISOString().substr(0, 10);
      if (this.toDate == tdate) {
        // this.toDate = null;
        const tomorrow = new Date(this.toDate);
        tomorrow.setDate(tomorrow.getDate() + (2*30));
        this.toDate = tomorrow.toISOString().substr(0, 10);
      }
    },
    getData() {
      this.flag = false;
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/analytics/nationality",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.graphData = response.data.data;
            // for(let i=0;i<this.graphData.length;i++) {
            //   this.series.push(this.graphData[i].count)
            //   this.chartOptions.labels.push(this.graphData[i].label)
            // }
            this.series = this.graphData.map((x) => x.count);
            this.chartOptions.labels = this.graphData.map((x) => x.label);
            this.flag = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.graphLabel {
  text-align: left !important;
  font-family: poppinsbold;
}
</style>